/**
 * Handles case when we can't load image
 */
import React, { useEffect, useState } from 'react'
import { isMobileLandscape } from '../../core/utils'
import { checkIfImageExists } from '../helper'
import AvatarFallbackIcon from './avatar_fallback.svg'

interface IAvatarProps {
  src: string
  isMobile: boolean
}

const Avatar = ({ src, isMobile }: IAvatarProps) => {
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    checkIfImageExists(src, (exists) => setError(!exists))
  }, [src])

  const onError = () => setError(true)

  return (
    <>
      <img
        width={isMobileLandscape(isMobile) ? 30 : 40}
        height={isMobileLandscape(isMobile) ? 30 : 40}
        src={error ? AvatarFallbackIcon : src}
        alt="avatar"
        style={{
          marginTop: isMobileLandscape(isMobile) ? 5 : 10,
          borderRadius: '50%',
        }}
        onError={onError}
      />
      {isMobileLandscape(isMobile) && (
        <span style={{ marginLeft: 10, color: 'white', fontSize: 16 }}>▾</span>
      )}
    </>
  )
}

export default Avatar
