import { convertFxThemeToColors } from './colorConvert'

const fxTheme = {
  primaryPalette: {
    name: 'customPrimary',
    hues: {
      default: '900',
      'hue-1': '800',
      'hue-2': '700',
      'hue-3': '50',
    },
    colors: {
      '50': '4e5863',
      '100': 'ffffff',
      '200': 'ffffff',
      '300': 'ffffff',
      '400': 'ffffff',
      '500': 'c6b06d',
      '600': 'c6b06d',
      '700': '000000',
      '800': 'FF0062',
      '900': '75F986',
      A100: 'ffffff',
      A200: 'ffffff',
      A400: 'ffffff',
      A700: 'ffffff',
      contrastDefaultColor: 'light',
    },
  },
  accentPalette: {
    name: 'customAccent',
    hues: {
      default: '900',
      'hue-1': '800',
      'hue-2': '700',
      'hue-3': '600',
    },
    colors: {
      '50': '000000',
      '100': '000000',
      '200': '000000',
      '300': '000000',
      '400': '000000',
      '500': '000000',
      '600': 'b2b5be',
      '700': '8491A3',
      '800': 'ffffff',
      '900': '75F986',
      A100: '000000',
      A200: '000000',
      A400: 'f94d1c',
      A700: 'c9c9c4',
      contrastDefaultColor: 'light',
    },
  },
  backgroundPalette: {
    name: 'grey2',
    colors: {
      '50': '000000',
      '100': '1e2836',
      '200': '16212e',
      '300': '2f2f2f',
      '400': '000000',
      '500': '16212e',
      '600': '06141f',
      '700': 'ffffff',
      '800': '16212e',
      '900': '000000',
      A100: 'dbdbdb',
      A200: '000000',
      A400: '000000',
      A700: '000000',
      contrastDefaultColor: 'light',
    },
    hues: {
      default: '200',
      'hue-1': '800',
      'hue-2': '600',
      'hue-3': '100',
    },
  },
  winPalette: {
    name: 'customwin',
    hues: {
      default: '900',
      'hue-1': '700',
      'hue-2': '500',
      'hue-3': '600',
    },
    colors: {
      '50': '000000',
      '100': '000000',
      '200': '000000',
      '300': '000000',
      '400': '000000',
      '500': '000000',
      '600': 'ffffff',
      '700': '2a7e56',
      '800': '000000',
      '900': '2e7d32',
      A100: '000000',
      A200: '000000',
      A400: '000000',
      A700: '4ddb00',
      contrastDefaultColor: 'light',
    },
  },
  breakEvenPalette: {
    name: 'custombreakEven',
    hues: {
      default: '800',
      'hue-1': '700',
      'hue-2': '600',
      'hue-3': '500',
    },
    colors: {
      '50': '000000',
      '100': '000000',
      '200': '000000',
      '300': '000000',
      '400': '000000',
      '500': '2196F3',
      '600': '1E88E5',
      '700': '1976D2',
      '800': '1565C0',
      '900': '000000',
      A100: '000000',
      A200: '000000',
      A400: '000000',
      A700: '4ddb00',
      contrastDefaultColor: 'light',
    },
  },
  lossPalette: {
    name: 'customloss',
    hues: {
      default: '800',
      'hue-1': '700',
      'hue-2': '500',
      'hue-3': '600',
    },
    colors: {
      '50': '000000',
      '100': '000000',
      '200': '000000',
      '300': '000000',
      '400': '000000',
      '500': 'F44336',
      '600': 'E53935',
      '700': 'D32F2F',
      '800': 'C62828',
      '900': '000000',
      A100: '000000',
      A200: '000000',
      A400: '000000',
      A700: 'D50000',
      contrastDefaultColor: 'light',
    },
  },
  warnPalette: {
    name: 'customwarn',
    hues: {
      default: '900',
      'hue-1': '700',
      'hue-2': '500',
      'hue-3': '600',
    },
    colors: {
      '50': '000000',
      '100': '000000',
      '200': '000000',
      '300': '000000',
      '400': '000000',
      '500': '000000',
      '600': 'b8432e',
      '700': 'b8432e',
      '800': '000000',
      '900': 'e25041',
      A100: '000000',
      A200: '000000',
      A400: '000000',
      A700: 'b46c30',
      contrastDefaultColor: 'light',
    },
  },
  upPalette: {
    name: 'customUp',
    hues: {
      default: '900',
      'hue-1': '700',
      'hue-2': '500',
      'hue-3': '600',
    },
    colors: {
      '50': 'ffffff',
      '100': 'ffffff',
      '200': 'ffffff',
      '300': 'ffffff',
      '400': 'ffffff',
      '500': 'ffffff',
      '600': '153f17',
      '700': '153f17',
      '800': 'ffffff',
      '900': 'FFCC00',
      A100: 'ffffff',
      A200: 'ffffff',
      A400: 'ffffff',
      A700: 'ffffff',
      contrastDefaultColor: 'light',
    },
  },
  downPalette: {
    name: 'customdown',
    hues: {
      default: '900',
      'hue-1': '700',
      'hue-2': '500',
      'hue-3': '600',
    },
    colors: {
      '50': 'ffffff',
      '100': 'ffffff',
      '200': 'ffffff',
      '300': 'ffffff',
      '400': 'ffffff',
      '500': 'ffffff',
      '600': '8e1f1d',
      '700': '8e1f1d',
      '800': 'ffffff',
      '900': 'FFCC00',
      A100: 'ffffff',
      A200: 'ffffff',
      A400: 'ffffff',
      A700: 'ffffff',
      contrastDefaultColor: 'light',
    },
  },
  chart: {
    name: 'customChart',
    hues: {
      default: '900',
      'hue-1': '800',
      'hue-2': '700',
      'hue-3': '600',
    },
    colors: {
      '50': '000000',
      '100': '000000',
      '200': '000000',
      '300': '000000',
      '400': '000000',
      '500': '000000',
      '600': '000000',
      '700': 'f03548',
      '800': '139981',
      '900': '39e48b',
      A100: '000000',
      A200: '000000',
      A400: '000000',
      A700: 'c9903d',
      contrastDefaultColor: 'light',
    },
  },
  backgroundChart: {
    name: 'customBackgroundChart',
    hues: {
      default: '900',
      'hue-1': '800',
      'hue-2': '700',
      'hue-3': '600',
    },
    colors: {
      '50': '000000',
      '100': '000000',
      '200': '000000',
      '300': '000000',
      '400': '000000',
      '500': '000000',
      '600': '282b35',
      '700': '000000',
      '800': '2a2e39',
      '900': '141823',
      A100: '000000',
      A200: '000000',
      A400: '000000',
      A700: 'c9903d',
      contrastDefaultColor: 'light',
    },
  },
}

const theme = convertFxThemeToColors(fxTheme)

export const FakeData = { theme, fxTheme }
