import React from 'react'

const RotateIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.735 10.75C2.1 9.965 0.93 8.38 0.75 6.5H0C0.25 9.58 2.83 12 5.975 12C6.09 12 6.195 12 6.305 11.985L4.4 10.075L3.735 10.75ZM6.025 0C5.91 0 5.805 0 5.695 0.02L7.6 1.925L8.265 1.25C9.9 2.035 11.07 3.62 11.25 5.5H12C11.75 2.42 9.17 0 6.025 0ZM8 7H9V4C9 3.73478 8.89464 3.48043 8.70711 3.29289C8.51957 3.10536 8.26522 3 8 3H5V4H8V7ZM4 8V2H3V3H2V4H3V8C3 8.26522 3.10536 8.51957 3.29289 8.70711C3.48043 8.89464 3.73478 9 4 9H8V10H9V9H10V8H4Z"
        fill={fill || 'white'}
      />
    </svg>
  )
}

export default RotateIcon
