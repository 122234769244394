import { t } from 'ttag'

const mobileOptions = [
  0.25,
  15,
  5 * 15,
  15 * 15,
  15 * 60,
  4 * 15 * 60,
  24 * 15 * 60,
  168 * 15 * 60,
]

const chartPeriodOptions = [
  {
    chartPeriod: 0.25,
    period: 'tick',
    candleStickPeriod: 0.25,
    periodLabel: t`Tick`,
    periodLabelEnglish: 'Tick',
    periodToolTip: t`Tick`,
    periodToolTipEnglish: 'Tick',
    unit: 'second',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: false,
  },
  {
    chartPeriod: 15,
    period: '1m',
    candleStickPeriod: 1,
    periodLabel: t`1m`,
    periodLabelEnglish: '1m',
    periodToolTip: t`1 Minute`,
    periodToolTipEnglish: '1 Minute',
    unit: 'minute',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 30,
    period: '2m',
    candleStickPeriod: 2,
    periodLabel: t`2m`,
    periodLabelEnglish: '2m',
    periodToolTip: t`2 Minutes`,
    periodToolTipEnglish: '2 Minutes',
    unit: 'minute',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 5 * 15,
    period: '5m',
    candleStickPeriod: 5,
    periodLabel: t`5m`,
    periodLabelEnglish: '5m',
    periodToolTip: t`5 Minutes`,
    periodToolTipEnglish: '5 Minutes',
    unit: 'minute',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 15 * 15,
    period: '15m',
    candleStickPeriod: 15,
    periodLabel: t`15m`,
    periodLabelEnglish: '15m',
    periodToolTip: t`15 Minutes`,
    periodToolTipEnglish: '15 Minutes',
    unit: 'minute',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 30 * 15,
    period: '30m',
    candleStickPeriod: 30,
    periodLabel: t`30m`,
    periodLabelEnglish: '30m',
    periodToolTip: t`30 Minutes`,
    periodToolTipEnglish: '30 Minutes',
    unit: 'minute',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 1 * 15 * 60,
    period: '1h',
    candleStickPeriod: 60,
    periodLabel: t`1h`,
    periodLabelEnglish: '1h',
    periodToolTip: t`1 Hour`,
    periodToolTipEnglish: '1 Hour',
    unit: 'hour',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 2 * 15 * 60,
    period: '2h',
    candleStickPeriod: 2 * 60,
    periodLabel: t`2h`,
    periodLabelEnglish: '2h',
    periodToolTip: t`2 Hours`,
    periodToolTipEnglish: '2 Hours',
    unit: 'hour',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 4 * 15 * 60,
    period: '4h',
    candleStickPeriod: 4 * 60,
    periodLabel: t`4h`,
    periodLabelEnglish: '4h',
    periodToolTip: t`4 Hours`,
    periodToolTipEnglish: '4 Hours',
    unit: 'hour',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 24 * 15 * 60,
    period: '1d',
    candleStickPeriod: 24 * 60,
    periodLabel: t`1d`,
    periodLabelEnglish: '1d',
    periodToolTip: t`1 Day`,
    periodToolTipEnglish: '1 Day',
    unit: 'day',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
  {
    chartPeriod: 168 * 15 * 60,
    period: '1w',
    candleStickPeriod: 24 * 60 * 7,
    periodLabel: t`1w`,
    periodLabelEnglish: '1w',
    periodToolTip: t`1 Week`,
    periodToolTipEnglish: '1 Week',
    unit: 'week',
    supportedOnLineChartType: true,
    supportedOnCandleChartType: true,
  },
]

const chartPeriodOptionsMobile = chartPeriodOptions.filter(({ chartPeriod }) =>
  mobileOptions.includes(chartPeriod)
)

export interface IPeriod {
  chartPeriod: number //  0.25,
  period: string // 'tick',
  candleStickPeriod: number // 0.25,
  periodLabel: string // 'Tick',
  periodLabelEnglish: string // 'Tick',
  periodToolTip: string // 'Tick',
  periodToolTipEnglish: string // 'Tick',
  unit: string // 'second',
  supportedOnLineChartType: boolean // true,
  supportedOnCandleChartType: boolean // false,
}

export { chartPeriodOptions, chartPeriodOptionsMobile }
