import styled, { css } from 'styled-components'
import { isMobileLandscape } from '../../../core/utils'

const Modal = styled.div<any>`
  position: absolute;
  top: calc(50% - 250px);
  left: ${(props) =>
    !props.isMobile || isMobileLandscape(props.isMobile)
      ? 'calc(50% - 240px)'
      : '20px'};
  ${(props) =>
    !props.isMobile || isMobileLandscape(props.isMobile)
      ? css`
          width: 580px;
        `
      : css`
          right: 20px;
        `};
  z-index: 41;
  border-radius: 24px;
`

const Contents = styled.div<any>`
  padding: ${(props) => (props.isMobile ? '30px 20px' : '30px 40px')};
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.colors.listBackgroundNormal};
  border-radius: 24px;
  position: relative;

  .file-upload {
    cursor: pointer;
    border-radius: 50%;
    margin-bottom: 25px;

    input {
      display: none;
    }
  }

  .avatar-preview {
    border-radius: 50%;
    border: 3px solid ${(props) => props.colors.primary};
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .croper-container {
    position: relative;
    margin-bottom: 20px;

    .arrow {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: transparent;
      z-index: 1;
    }

    .arrow-top-left {
      top: 0;
      left: 0;
      border-top: 2px solid ${(props) => props.colors.primaryText};
      border-left: 2px solid ${(props) => props.colors.primaryText};
    }
    .arrow-top-right {
      top: 0;
      right: 0;
      border-top: 2px solid ${(props) => props.colors.primaryText};
      border-right: 2px solid ${(props) => props.colors.primaryText};
    }
    .arrow-bottom-left {
      bottom: 0;
      left: 0;
      border-bottom: 2px solid ${(props) => props.colors.primaryText};
      border-left: 2px solid ${(props) => props.colors.primaryText};
    }
    .arrow-bottom-right {
      bottom: 0;
      right: 0;
      border-bottom: 2px solid ${(props) => props.colors.primaryText};
      border-right: 2px solid ${(props) => props.colors.primaryText};
    }
  }

  .cropper-modal {
    opacity: 0.8;
  }
  cropper-crop-box,
  .cropper-view-box {
    border-radius: 50%;
  }
  .cropper-view-box {
    box-shadow: 0 0 0 1px ${(props) => props.colors.primaryText};
    outline: 0;
  }
  .cropper-face {
    background-color: inherit !important;
  }

  .cropper-dashed,
  .cropper-line {
    display: none !important;
  }
  .cropper-view-box {
    outline: inherit !important;
  }
  .cropper-point {
    background-color: ${(props) => props.colors.primaryText};
    width: 5px !important;
    height: 5px !important;
    opacity: 0.75 !important;
  }
  .cropper-point.point-e,
  .cropper-point.point-n,
  .cropper-point.point-w,
  .cropper-point.point-s {
    transform: rotate(45deg);
  }
  .cropper-point.point-se {
    top: calc(85% + 1px);
    right: 14%;
  }
  .cropper-point.point-sw {
    top: calc(85% + 1px);
    left: 14%;
  }
  .cropper-point.point-nw {
    top: calc(15% - 6px);
    left: 14%;
  }
  .cropper-point.point-ne {
    top: calc(15% - 6px);
    right: 14%;
  }

  .loadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Caption = styled.h3<any>`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  letter-spacing: -0.08px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  color: ${(props) => props.color};
`

const SubCaption = styled.h3<any>`
  margin: 0 0 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.12px;
  color: ${(props) => props.color};
  text-align: center;

  .avatar-error {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
    font-style: italic;
  }
`

const SubmitButton = styled.a<any>`
  display: block;
  ${(props) =>
    props.isMobile && !isMobileLandscape(props.isMobile)
      ? css`
          flex: 1;
          max-width: 240px;
        `
      : css`
          width: 240px;
        `}
  height: 38px;
  line-height: 38px;
  padding: 0 20px;
  background-color: ${(props) => props.colors.primary};
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.09px;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  color: ${(props) => props.colors.textfieldBackground};
`

const CancelButton = styled.a<any>`
  display: block;
  ${(props) =>
    props.isMobile && !isMobileLandscape(props.isMobile)
      ? css`
          flex: 1;
          max-width: 240px;
        `
      : css`
          width: 240px;
        `}
  height: 38px;
  line-height: 38px;
  padding: 0 20px;
  margin-right: 20px;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.09px;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: ${(props) => props.colors.listBackgroundActive};
  color: ${(props) => props.colors.primary};
`

const RotateButton = styled.a<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  line-height: 22px;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.094px;
  border-radius: 11px;
  color: ${(props) => props.colors.primaryText};
  background-color: ${(props) => props.colors.listBackgroundActive};
  padding: 0 5px;
  margin-bottom: 20px;
`

const SuccessText = styled.span<any>`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  color: ${(props) => props.color};
  margin-bottom: 20px;
  padding: ${(props) =>
    props.isMobile && !isMobileLandscape(props.isMobile) ? '0' : '0 70px'};
`

export {
  Modal,
  Contents,
  Caption,
  SubCaption,
  SubmitButton,
  CancelButton,
  RotateButton,
  SuccessText,
}
