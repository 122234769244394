import React from 'react'

const EducationIcon = (props: any) => {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666667 19.5002H19.3333C19.7015 19.5002 20 19.2017 20 18.8335V1.50016C20 1.13197 19.7015 0.833496 19.3333 0.833496H0.666667C0.298477 0.833496 0 1.13197 0 1.50016V18.8335C0 19.2017 0.298477 19.5002 0.666667 19.5002ZM18.6667 18.1668H1.33333V2.16683H18.6667V18.1668Z"
        fill={props.color || '#8491A3'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00666 14.747C7.10629 14.8035 7.2188 14.8334 7.33332 14.8337C7.45298 14.8337 7.57042 14.8014 7.67332 14.7404L14.34 10.7404C14.5424 10.6203 14.6665 10.4024 14.6665 10.167C14.6665 9.9317 14.5424 9.7138 14.34 9.59371L7.67332 5.59371C7.46728 5.47154 7.21154 5.46931 7.00339 5.58785C6.79525 5.7064 6.6667 5.9275 6.66666 6.16704V14.167C6.66707 14.4075 6.797 14.6292 7.00666 14.747ZM12.7067 10.167L7.99999 12.987V7.34704L12.7067 10.167Z"
        fill={props.color || '#8491A3'}
      />
    </svg>
  )
}

export default EducationIcon
