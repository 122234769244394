import React from 'react'

const AccountIcon = (props: any) => {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5714 3.69064H17.8571C19.0406 3.69064 20 4.65003 20 5.8335V18.6906H0V5.8335C0 4.65003 0.95939 3.69064 2.14286 3.69064H6.42857V2.26207C6.42857 1.47309 7.06816 0.833496 7.85714 0.833496H12.1429C12.9318 0.833496 13.5714 1.47309 13.5714 2.26207V3.69064ZM12.1429 2.26207H7.85714V3.69064H12.1429V2.26207ZM18.5714 17.2621H1.42857V9.40493H2.85714V12.2621H7.14286V9.40493H12.8571V12.2621H17.1429V9.40493H18.5714V17.2621ZM5.71429 9.40493H4.28571V10.8335H5.71429V9.40493ZM14.2857 9.40493H15.7143V10.8335H14.2857V9.40493ZM1.42857 7.97635H17.1429H18.5714V5.8335C18.5714 5.43901 18.2516 5.11921 17.8571 5.11921H2.14286C1.74837 5.11921 1.42857 5.43901 1.42857 5.8335V7.97635Z"
        fill={props.color || '#8491A3'}
      />
    </svg>
  )
}

export default AccountIcon
