import React from 'react'

const EditAvatarIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill={fill || '#75F986'} />
      <path
        d="M6.57777 11.644C6.35018 11.8717 6.0415 11.9997 5.71955 12H4V10.2915C4 9.96975 4.128 9.66086 4.35556 9.43331M6.57777 11.644L4.35556 9.43331M6.57777 11.644L11.0107 7.20931M4.35556 9.43331L8.79288 4.99109L9.42666 4.35642C9.53944 4.24347 9.67337 4.15386 9.82081 4.0927C9.96824 4.03154 10.1263 4.00004 10.2859 4C10.4455 3.99996 10.6036 4.03138 10.751 4.09246C10.8985 4.15354 11.0325 4.24309 11.1453 4.35598L11.6458 4.85687C11.8736 5.08466 12.0015 5.39361 12.0015 5.71576C12.0015 6.0379 11.8736 6.34685 11.6458 6.57464L11.0107 7.20931M11.0107 7.20931L8.79244 4.99109"
        stroke="#263346"
        strokeWidth="0.999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditAvatarIcon
