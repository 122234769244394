import React from 'react'

export const CryptoIcon = (props: any) => {
  const { width, height, color } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bitcoin-logo-blue">
        <path
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2616 34.4782C22.6383 36.8155 32.1337 31.1116 34.4711 21.7349H34.4705C36.8079 12.3598 31.1028 2.86277 21.7272 0.525426C12.3538 -1.81192 2.85895 3.89363 0.522695 13.2703C-1.81574 22.6449 3.8898 32.1408 13.2616 34.4782ZM21.3636 10.5934C23.7879 11.4285 25.5608 12.6803 25.2125 15.0089C24.9604 16.7135 24.0154 17.5387 22.7608 17.8286C24.4835 18.726 25.3601 20.1014 24.525 22.4857C23.4887 25.4465 21.0267 25.6964 17.7525 25.0768L16.9574 28.2613L15.0379 27.7828L15.8221 24.641C15.3244 24.5168 14.8158 24.3861 14.2919 24.2434L13.5055 27.4005L11.5876 26.922L12.3822 23.7315C12.2101 23.6874 12.0369 23.6423 11.8626 23.5969L11.8625 23.5969L11.8621 23.5968L11.8621 23.5967C11.5824 23.5239 11.2996 23.4502 11.0134 23.3788L8.51473 22.7553L9.46793 20.5569C9.46793 20.5569 10.8827 20.9332 10.863 20.9053C11.4071 21.0398 11.6483 20.6854 11.7435 20.4492L12.9991 15.4152L13.202 15.4655C13.1254 15.4354 13.056 15.4152 13.0024 15.4015L13.8982 11.808C13.9217 11.4 13.7811 10.8854 13.0035 10.6913C13.0336 10.6711 11.6089 10.3446 11.6089 10.3446L12.1197 8.29379L14.7677 8.95496L14.7655 8.9648C15.1636 9.06379 15.5738 9.15785 15.9916 9.253L16.778 6.09972L18.6975 6.57824L17.9264 9.66972C18.4402 9.78541 18.9528 9.9061 19.4643 10.0318L20.2299 6.9605L22.15 7.43902L21.3636 10.5934ZM15.2872 22.0289C16.8528 22.4428 20.2802 23.3488 20.8249 21.1601C21.3825 18.9247 18.0643 18.1793 16.4432 17.8152L16.4432 17.8152C16.2614 17.7744 16.101 17.7383 15.9697 17.7055L14.9154 21.9323C15.0222 21.9589 15.1473 21.9919 15.2872 22.0289ZM21.4188 14.975C20.9225 16.9626 18.0734 16.2023 16.7671 15.8537C16.6484 15.822 16.5424 15.7937 16.4521 15.7712L17.408 11.9376C17.5176 11.9649 17.6518 11.9946 17.8039 12.0283C19.1564 12.3277 21.9256 12.9408 21.4188 14.9744V14.975Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
