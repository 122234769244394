/**
 * Implement a generic modal to show user that we have a backend problem
 */
import React from 'react'

const NetworkErrorModal = () => {
    return (
        <div>
            
        </div>
    )
}

export default NetworkErrorModal;