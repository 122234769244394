export enum AssetGroup {
	favorites,
	forex,
	stocks,
	commodities,
	indices,
	none,
	crypto,
	mostTraded,
	recentlyAdded,
	gainersLosers,
}
