import React from 'react'

const Vip = (props: any) => {
  return (
    <svg
      width="35"
      height="15"
      viewBox="0 0 35 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="35" height="15" rx="2" fill="#C6FCFF" />
      <mask
        id="mask0_129_2354"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="35"
        height="15"
      >
        <rect width="35" height="15" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_129_2354)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 0V15H0L35 0Z"
          fill="#A5F4F9"
        />
        <path
          d="M12.4441 9.51562L14.1189 3.89062H15.7498L13.2742 11H12.2L12.4441 9.51562ZM10.9012 3.89062L12.5711 9.51562L12.825 11H11.741L9.28008 3.89062H10.9012ZM17.7715 3.89062V11H16.3115V3.89062H17.7715ZM21.7121 8.46582H19.9006V7.32324H21.7121C21.9921 7.32324 22.2199 7.27767 22.3957 7.18652C22.5715 7.09212 22.7001 6.96191 22.7814 6.7959C22.8628 6.62988 22.9035 6.44271 22.9035 6.23438C22.9035 6.02279 22.8628 5.82585 22.7814 5.64355C22.7001 5.46126 22.5715 5.31478 22.3957 5.2041C22.2199 5.09342 21.9921 5.03809 21.7121 5.03809H20.4084V11H18.9436V3.89062H21.7121C22.2688 3.89062 22.7456 3.99154 23.1428 4.19336C23.5432 4.39193 23.8492 4.66699 24.0607 5.01855C24.2723 5.37012 24.3781 5.77214 24.3781 6.22461C24.3781 6.68359 24.2723 7.08073 24.0607 7.41602C23.8492 7.7513 23.5432 8.01009 23.1428 8.19238C22.7456 8.37467 22.2688 8.46582 21.7121 8.46582Z"
          fill="#1593A5"
        />
      </g>
    </svg>
  )
}

export default Vip
