/* eslint-disable no-restricted-globals */
/**
 * Implements a global loader from bpFxCfd
 * Uses bodymovin library which contains definition
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import * as bodymovin from 'bodymovin'
import './styles.scss'
import ThemedIcon from '../ThemedIcon'

interface IGlobalLoaderProps {
  theme: any
  zIndex?: number
  containerId?: string
  loaderWidth?: number
}

const GlobalStyle = createGlobalStyle<{ color: string }>`
	.bg-loader-container svg path {
    	fill: ${(props) => props.color};
	}
`

/**
 * Keep in mind:
 * Default zIndex for GlobalLoader is 100 (to overlay all stuff except theme editor)
 * Should be reduced for chart to 40
 */
const GlobalLoader = ({
  theme,
  zIndex,
  containerId,
  loaderWidth,
}: IGlobalLoaderProps) => {
  const [loadingIconName, setLoadingIconName] = useState<string | null>(null)

  useEffect(() => {
    const url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href
    if (url.includes('xpoption.com')) {
      return setLoadingIconName('xpoption_com')
    } else if (url.includes('exnie.com')) {
      return setLoadingIconName('exnie_com')
    } else {
      bodymovin.loadAnimation({
        container: document.getElementById(containerId || 'mainLoader'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('./data.json'),
      })
      bodymovin.play()
    }

    return () => {
      bodymovin.stop()
    }
  }, [containerId])

  const styles = {
    backgroundColor: theme.backgroundDefault,
    zIndex: zIndex || 100,
  }

  return (
    <div className="bg-loader-container" style={styles}>
      {!loadingIconName && <GlobalStyle color={theme.primary} />}
      {!loadingIconName && (
        <div
          className="main-loader"
          id={containerId || 'mainLoader'}
          style={{ width: loaderWidth || 130 }}
        />
      )}
      {loadingIconName && (
        <ThemedIcon
          width={loaderWidth || 240}
          height={loaderWidth || 240}
          src={`${process.env.PUBLIC_URL}/static/icons/loading/${loadingIconName}.svg`}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({ theme: state.theme })

export default connect(mapStateToProps)(GlobalLoader)
