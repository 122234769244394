import React from 'react'

const AddAvatarIcon = ({ stroke }: { stroke: string }) => {
  return (
    <svg
      width="212"
      height="212"
      viewBox="0 0 212 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M134.125 106C134.125 106.895 133.769 107.754 133.136 108.386C132.504 109.019 131.645 109.375 130.75 109.375H109.375V130.75C109.375 131.645 109.019 132.504 108.386 133.136C107.754 133.769 106.895 134.125 106 134.125C105.105 134.125 104.246 133.769 103.614 133.136C102.981 132.504 102.625 131.645 102.625 130.75V109.375H81.25C80.3549 109.375 79.4964 109.019 78.8635 108.386C78.2306 107.754 77.875 106.895 77.875 106C77.875 105.105 78.2306 104.246 78.8635 103.614C79.4964 102.981 80.3549 102.625 81.25 102.625H102.625V81.25C102.625 80.3549 102.981 79.4964 103.614 78.8635C104.246 78.2306 105.105 77.875 106 77.875C106.895 77.875 107.754 78.2306 108.386 78.8635C109.019 79.4964 109.375 80.3549 109.375 81.25V102.625H130.75C131.645 102.625 132.504 102.981 133.136 103.614C133.769 104.246 134.125 105.105 134.125 106Z"
        fill="#8491A3"
      />
      <circle
        cx="106"
        cy="106"
        r="104.5"
        fill="#8491A3"
        fillOpacity="0.2"
        stroke={stroke || '#75F986'}
        strokeWidth="3"
      />
    </svg>
  )
}

export default AddAvatarIcon
