import React from 'react'

const DashboardIcon = (props: any) => {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4133 1.41977C10.6211 0.668019 9.37894 0.668019 8.58666 1.41977L0.193329 9.80644L1.14 10.7531L2.66666 9.22644V17.4998C2.66666 18.6043 3.56209 19.4998 4.66666 19.4998H15.3333C16.4379 19.4998 17.3333 18.6043 17.3333 17.4998V9.22644L18.86 10.7531L19.8067 9.80644L11.4133 1.41977ZM8.66666 18.1664V13.4998H11.3333V18.1664H8.66666ZM15.3333 18.1664C15.7015 18.1664 16 17.868 16 17.4998V7.8931L10.4733 2.35977C10.3482 2.23356 10.1778 2.16257 10 2.16257C9.82224 2.16257 9.65184 2.23356 9.52666 2.35977L4 7.8931V17.4998C4 17.868 4.29847 18.1664 4.66666 18.1664H7.33333V12.1664H12.6667V18.1664H15.3333Z"
        fill={props.color || '#8491A3'}
      />
    </svg>
  )
}

export default DashboardIcon
