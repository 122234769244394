/**
 * Handles case when we can't load image
 */
import React, { useEffect, useState } from 'react'
import { isMobileLandscape } from '../../../core/utils'
import { ImgAvatar } from './styled'
import { checkIfImageExists } from '../../helper'
import AvatarFallbackIcon from '../../Header/avatar_fallback.svg'

interface IAvatarProps {
  src: string
  isMobile: boolean
  acronym: string
  onClickAvatar?: () => void
}

const Avatar = ({ src, isMobile, acronym, onClickAvatar }: IAvatarProps) => {
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    checkIfImageExists(src, (exists) => setError(!exists))
  }, [src])

  const onError = () => setError(true)

  return (
    <>
      <ImgAvatar
        size={isMobileLandscape(isMobile) ? 30 : 40}
        onClick={onClickAvatar}
      >
        <img
          width={isMobileLandscape(isMobile) ? 30 : 40}
          height={isMobileLandscape(isMobile) ? 30 : 40}
          src={error ? AvatarFallbackIcon : src}
          alt="avatar"
          style={{
            borderRadius: '50%',
          }}
          onError={onError}
        />
      </ImgAvatar>
    </>
  )
}

export default Avatar
