/**
 * React to practice expired event
 * Normally we should use accountSwitchRedirectUrl, but
 */
import React, { useRef, useState } from 'react'
import { t } from 'ttag'
import { Overlay } from '@react-md/overlay'
import { connect } from 'react-redux'
import {
  Modal,
  Contents,
  SubmitButton,
  Caption,
  SubCaption,
  CancelButton,
  RotateButton,
  SuccessText,
} from './styled'
import { actionCloseModal } from '../../../actions/modal'
import AddAvatarIcon from './addAvatarIcon'
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { IUserInfo } from '../../../core/API'
import RotateIcon from './rotateIcon'
import CloseBtnRound from '../../Sidebar/CloseBtnRound'
import { api } from '../../../core/createAPI'
import GlobalLoader from '../../ui/GlobalLoader'
import { actionGetUserAvatar } from '../../../actions/account'

interface IUploadAvatarModalProps {
  userInfo: IUserInfo
  colors: any
  isMobile: boolean
  actionCloseModal: () => void
  actionGetUserAvatar: () => void
}

const UploadAvatarModal = (props: IUploadAvatarModalProps) => {
  const { userInfo, colors, isMobile, actionCloseModal, actionGetUserAvatar } =
    props
  const { userImage, firstName, lastName, userID } = userInfo

  const cropperRef = useRef<ReactCropperElement>(null)
  const inputRef = useRef<any>(null)

  const [selectedFile, setSelectedFile] = useState<any | undefined>(undefined)
  const [preview, setPreview] = useState<string | undefined>(userImage)
  const [error, setError] = useState<string>('')
  const [uploading, setUploading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const handleFileChange = (event: any) => {
    if (!event.target.files.length) return

    const file = event.target.files[0]

    if (file.size > 8388608) {
      setError('Maximum file size is 8MB')
      return
    }
    if (file.type.split('/')[0] !== 'image') {
      setError('Please select an image file')
      return
    }
    error && setError('')
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFile(e.target?.result)
    }
    reader.readAsDataURL(file)
  }

  const onClickSelectAvatar = () => inputRef.current?.click()

  const onCancelCrop = () => setSelectedFile(undefined)

  const onSubmitUploadAvatar = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      cropperRef.current?.cropper
        .getCroppedCanvas()
        .toBlob(async (blob: Blob | null) => {
          if (!blob) return
          setUploading(true)
          const formData = new FormData()
          formData.append(
            'file',
            blob as Blob,
            `${userID}_${firstName}_${lastName}.png`
          )
          await api.uploadAvatar(formData)
          actionGetUserAvatar()
          setUploading(false)
          setPreview(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
          setSelectedFile(undefined)
          setSubmitted(true)
        })
    }
  }

  return (
    <>
      <Modal isMobile={isMobile} colors={colors}>
        {!selectedFile && (
          <Contents colors={colors} isMobile={isMobile}>
            <CloseBtnRound
              onClick={actionCloseModal}
              isSquare={true}
              top={20}
              right={20}
            />
            <Caption color={colors.secondaryText}>{t`Profile picture`}</Caption>
            {submitted && (
              <SuccessText isMobile={isMobile} color={colors.primary}>
                {t`Your photo has been successfully changed and can now be viewed on your profile!`}
              </SuccessText>
            )}
            <div className="file-upload">
              {!preview && <AddAvatarIcon stroke={colors.primary} />}
              {preview && (
                <img
                  className="avatar-preview"
                  width={206}
                  height={206}
                  alt="Avatar"
                  src={preview}
                />
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            {!submitted && (
              <SubCaption color={colors.secondaryText}>
                {error && <div className="avatar-error">{error}</div>}
                <div>{t`Supported file formats: jpg, jpeg, png, gif, pdf, tiff`}</div>
                <div style={{ marginTop: 5 }}>
                  {t`Please note that files should not exceed 8MB`}
                </div>
              </SubCaption>
            )}
            {submitted ? (
              <div className="buttons-container">
                <SubmitButton
                  isMobile={isMobile}
                  colors={colors}
                  onClick={actionCloseModal}
                >
                  {t`Done`}
                </SubmitButton>
              </div>
            ) : (
              <div className="buttons-container">
                <CancelButton
                  isMobile={isMobile}
                  colors={colors}
                  onClick={() => setPreview(undefined)}
                >
                  {t`Remove`}
                </CancelButton>
                <SubmitButton
                  isMobile={isMobile}
                  colors={colors}
                  onClick={onClickSelectAvatar}
                >
                  {preview ? t`Change` : t`Upload`}
                </SubmitButton>
              </div>
            )}
          </Contents>
        )}
        {selectedFile && (
          <Contents colors={colors} isMobile={isMobile}>
            <CloseBtnRound
              onClick={actionCloseModal}
              isSquare={true}
              top={20}
              right={20}
            />
            <Caption
              color={colors.secondaryText}
            >{t`Profile picture crop e rotate`}</Caption>
            <div className="croper-container">
              <div className="arrow arrow-top-left" />
              <div className="arrow arrow-top-right" />
              <div className="arrow arrow-bottom-left" />
              <div className="arrow arrow-bottom-right" />
              <Cropper
                src={selectedFile}
                style={{ height: 206, width: 206 }}
                aspectRatio={1}
                ref={cropperRef}
                viewMode={2}
              />
            </div>
            {!submitted && (
              <RotateButton
                colors={colors}
                onClick={() => cropperRef.current?.cropper.rotate(90)}
              >
                <RotateIcon fill={colors.primaryText} />
                <span style={{ marginLeft: 5 }}>{t`Rotate`}</span>
              </RotateButton>
            )}
            <div className="buttons-container">
              <CancelButton
                isMobile={isMobile}
                colors={colors}
                onClick={onCancelCrop}
              >
                {t`Cancel`}
              </CancelButton>
              <SubmitButton
                isMobile={isMobile}
                colors={colors}
                onClick={onSubmitUploadAvatar}
              >
                {t`Submit`}
              </SubmitButton>
            </div>
            {uploading && <GlobalLoader zIndex={42} />}
          </Contents>
        )}
      </Modal>
      <Overlay
        id="modal-overlay"
        visible={true}
        onRequestClose={() => {}}
        style={{
          zIndex: 40,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(10px)',
        }}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  userInfo: state.account.userInfo,
  registry: state.registry.data,
  colors: state.theme,
  isMobile: state.registry.isMobile,
})

export default connect(mapStateToProps, {
  actionCloseModal,
  actionGetUserAvatar,
})(UploadAvatarModal)
